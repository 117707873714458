(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/day-time-limit.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/day-time-limit.js');

'use strict';

function dayTimeLimit(_ref) {
  var _svs$day_time_limit;
  let {
    next,
    cancel
  } = _ref;
  if ((_svs$day_time_limit = svs.day_time_limit) !== null && _svs$day_time_limit !== void 0 && (_svs$day_time_limit = _svs$day_time_limit.data) !== null && _svs$day_time_limit !== void 0 && (_svs$day_time_limit = _svs$day_time_limit.dayTimeLimit) !== null && _svs$day_time_limit !== void 0 && _svs$day_time_limit.dayTimeExpired) {
    svs.tur.pay.logger.info('Users day time limit has expired. Denying payment.');
    svs.accountservices.day_time_limit.notices.pay();
    cancel();
  } else {
    next();
  }
}
svs.tur.pay.account_services.dayTimeLimit = dayTimeLimit;

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/prescript.js');

'use strict';

svs.tur = svs.tur || {};
svs.tur.pay = {
  account_services: {},
  api_calls: {},
  communication: {},
  dialogs: {},
  misc: {},
  utils: {},
  logger: new svs.core.log.Logger('tur:pay')
};

 })(window);
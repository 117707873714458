(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/dialogs/confirm-payment.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/dialogs/confirm-payment.js');

'use strict';

const currency = svs.utils.format.Currency;

function confirmPayment(_ref) {
  let {
    next,
    cancel,
    accountBalance,
    isSyndicate,
    syndicate,
    price,
    title,
    messages = [],
    product,
    isProlongation
  } = _ref;
  let customer = 'Ditt';
  if (isSyndicate) {
    customer = svs.tur.pay.utils.format_text.addOwningGrammarApostrophe(syndicate.syndicateName || 'Laget');
  }
  messages.push("".concat(customer, " saldo: ").concat(currency(accountBalance), " kr"));
  const message = messages.map(text => ({
    type: svs.ui.dialog.message.TEXT,
    align: svs.ui.dialog.message.align.CENTER,
    text
  }));
  const onPopState = () => {
    dialog.close();
    cancel();
    window.removeEventListener('popstate', onPopState);
  };
  const dialog = new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    type: svs.ui.dialog.type.INFO,
    icon: 'games',
    title,
    message,
    actions: [{
      title: isProlongation ? 'Ändra' : 'Avbryt',
      callback: () => {
        dialog.close();
        if (product) {
          svs.components.analytics.trackEvent({
            category: 'betslip_cancelled',
            action: 'yes',
            opt_label: product
          });
        }
        window.removeEventListener('popstate', onPopState);
        cancel();
      }
    }, {
      title: "Betala ".concat(price, " kr"),
      callback: () => {
        dialog.close();
        window.removeEventListener('popstate', onPopState);
        next();
      }
    }]
  });
  window.addEventListener('popstate', onPopState);
  svs.ui.dialog.api.add(dialog);
}
svs.tur.pay.dialogs.confirmPayment = confirmPayment;

 })(window);
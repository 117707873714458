(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/communication/correction-email.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/communication/correction-email.js');

'use strict';

function correctionEmail(_ref) {
  let {
    next,
    product
  } = _ref;
  new svs.tur.CorrectionEmail({
    productName: product,
    callback: next
  }).show();
}
svs.tur.pay.communication.correctionEmail = correctionEmail;

 })(window);
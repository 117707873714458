(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/api-calls/wagers.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/api-calls/wagers.js');

'use strict';

function payMultipleWagers(_ref) {
  let {
    next,
    cancel,
    wagers
  } = _ref;
  const jupiterOptions = {
    method: 'POST',
    path: !svs.core.detect.feature('ft-its-api-version') ? '/wager/9/multiwagers' : '/wager/10/multiwagers',
    data: {
      wagers
    }
  };
  const success = _ref2 => {
    let {
      regWagerResults
    } = _ref2;
    return next({
      regWagerResults
    });
  };
  const error = _ref3 => {
    let {
      error
    } = _ref3;
    svs.tur.apierrors.showErrorMessage({
      response: error,
      onConfirm: cancel({
        error
      })
    });
  };
  svs.core.jupiter.call(jupiterOptions, success, error);
}

function payWagers(_ref4) {
  let {
    next,
    cancel,
    wagers
  } = _ref4;
  if (wagers.length > 1) {
    return payMultipleWagers({
      next,
      cancel,
      wagers
    });
  }
  svs.core.jupiter.call({
    method: 'POST',
    path: !svs.core.detect.feature('ft-its-api-version') ? '/wager/9/wagers' : '/wager/10/wagers',
    data: wagers[0]
  }, regWagerResult => next({
    regWagerResults: [{
      status: 'SUCCESS',
      regWagerResult
    }]
  }), xhr => {
    const response = JSON.parse(xhr.responseText);
    next({
      regWagerResults: [{
        status: 'FAILURE',
        tpError: {
          errCode: response.error.code
        }
      }]
    });
  });
}
Object.assign(svs.tur.pay.api_calls, {
  payMultipleWagers,
  payWagers
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/player-suspension.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/player-suspension.js');

'use strict';

function playerSuspension(_ref) {
  let {
    next,
    cancel
  } = _ref;
  if (svs.core.userSession.isBlocked()) {
    svs.tur.pay.logger.info('User is blocked in NAR. Denying payment.');
    svs.accountservices.national_suspension_message.showNationalSuspensionPayMessage();
    cancel();
  } else {
    next();
  }
}
svs.tur.pay.account_services.playerSuspension = playerSuspension;

 })(window);
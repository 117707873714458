(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/dialogs/general-error.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/dialogs/general-error.js');

'use strict';

function generalError(_ref) {
  let {
    cancel
  } = _ref;
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    type: svs.ui.dialog.type.ERROR,
    icon: 'exclamation-sign',
    title: 'Fel',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      align: svs.ui.dialog.message.align.CENTER,
      text: 'Ett oväntat fel har inträffat.'
    }],
    actions: [{
      title: 'Ok',
      callback: (e, forms, dialog) => {
        dialog.close();
        cancel();
      }
    }]
  }));
}
svs.tur.pay.dialogs.generalError = generalError;

 })(window);
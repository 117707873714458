(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/dialogs/payment-success.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/dialogs/payment-success.js');

'use strict';

function showTrissInfoDialog(next) {
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    icon: 'nav-my-games',
    title: 'Glöm inte att skrapa',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: 'Du kan skrapa din Triss när du vill inom en vecka. Därefter skrapas och rättas lotten automatiskt.'
    }],
    actions: [{
      title: 'Ok',
      callback: (e, forms, dialog) => {
        dialog.close();
        next();
      }
    }]
  }));
}
function getActions(_ref) {
  let {
    next,
    successfulTickets
  } = _ref;
  if (successfulTickets.length) {
    return [{
      title: 'Skrapa senare',
      callback: (e, forms, dialog) => {
        dialog.close();
        showTrissInfoDialog(next);
      }
    }, {
      title: 'Skrapa nu',
      callback: () => {
        const wagerId = successfulTickets[0].wagerId;
        svs.utils.url.navigate(svs.core.urlMapping.get('triss_BetDetail').replace(':wagerId', wagerId));
      }
    }];
  }
  return [{
    title: 'Ok',
    callback: (e, forms, dialog) => {
      dialog.close();
      next();
    }
  }];
}

function paymentSuccess(_ref2) {
  let {
    next,
    product,
    isSyndicate,
    title,
    messages,
    successfulTickets
  } = _ref2;
  title = title || "".concat(isSyndicate ? 'Ert' : 'Ditt', " ").concat(product, " \xE4r betalat. Lycka till!");
  messages = messages || ["Se dina lagda spel i <a href=\"".concat(svs.core.urlMapping.get('myBetsHome'), "\">Mina spel</a>.")];
  messages = messages.map(text => ({
    type: svs.ui.dialog.message.TEXT,
    align: svs.ui.dialog.message.align.CENTER,
    text
  }));
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    type: svs.ui.dialog.type.INFO,
    icon: 'thumbs-up',
    title,
    message: messages,
    actions: getActions({
      next,
      successfulTickets
    })
  }));
}
svs.tur.pay.dialogs.paymentSuccess = paymentSuccess;

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/misc/grassroots.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/misc/grassroots.js');

'use strict';

async function getGrassrootsReceivers(_ref) {
  let {
    next,
    isSyndicate
  } = _ref;
  if (isSyndicate) {
    return next({
      grassrootsReceivers: []
    });
  }
  let grassrootsReceivers;
  try {
    ({
      value: grassrootsReceivers
    } = await svs.tur.usersettings.get({
      key: 'receivers'
    }));
  } catch (e) {
    svs.tur.pay.warn('Could not get grassroots receivers from tur usersettings', e);
  }
  next({
    grassrootsReceivers
  });
}

function showGrassrootsToaster(_ref2) {
  let {
    next
  } = _ref2;
  new svs.tur.GrassrootsToaster().show();
  next();
}
svs.tur.pay.misc.getGrassrootsReceivers = getGrassrootsReceivers;
svs.tur.pay.misc.showGrassrootsToaster = showGrassrootsToaster;

 })(window);
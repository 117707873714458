(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/api-calls/pay-ticket.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/api-calls/pay-ticket.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const buyTicket = async (ticket, channel) => {
  const {
    price,
    packageGameConfig,
    type,
    theme: defaultTheme
  } = ticket;
  const {
    skinId = 1,
    theme = {
      themeName: defaultTheme
    }
  } = packageGameConfig || {};
  return await svs.core.jupiter.callAsync({
    method: 'POST',
    path: '/einstant/1/bet',
    data: {
      gameCode: type,
      stake: price * 100,
      channel,
      revelationData: {
        themeId: theme === null || theme === void 0 ? void 0 : theme.themeName,
        skinId
      }
    }
  });
};
const trackPayedTicketTransaction = (ticket, transactionId) => {
  const trackingItem = new svs.components.analytics.transactionItem(ticket.type, ticket.name, ticket.price, 1);
  const transaction = new svs.components.analytics.transaction(transactionId, ticket.price, [trackingItem], 0, 0, undefined, ticket.name);
  svs.components.analytics.trackTransaction(transaction);
};
const payTicket = async _ref => {
  let {
    next,
    ticket,
    successfulTickets = [],
    failedTickets = [],
    channel,
    shouldTrackEventPayment = true
  } = _ref;
  if (ticket) {
    svs.ui.loader.show();
    let wagerId;
    try {
      const data = await buyTicket(ticket, channel);
      wagerId = data.response.receiptId;
      successfulTickets.push(_objectSpread(_objectSpread({}, ticket), {}, {
        wagerId
      }));
      svs.tur.pay.logger.info('Ticket payment success', wagerId);
      const track = {
        category: 'Wager',
        action: 'Paid',
        opt_label: data.response.serviceName
      };
      if (shouldTrackEventPayment) {
        svs.components.analytics.trackEvent(track);
      }
      trackPayedTicketTransaction(ticket, data.response.data.id);
    } catch (e) {
      failedTickets.push(ticket);
      svs.tur.pay.logger.error("Ticket payment of ".concat(ticket.type, " failed: ").concat(e));
    }
    svs.ui.loader.hide();
  }
  next({
    successfulTickets,
    failedTickets
  });
};
svs.tur.pay.api_calls.payTicket = payTicket;

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/dialogs/payment-success-content.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/dialogs/payment-success-content.js');

'use strict';

const productMapping = svs.numbergames.productMapping;
const {
  format_text: formatText
} = svs.tur.pay.utils;

function getSuccessTitle(_ref) {
  let {
    product,
    isSyndicate,
    successfulWagers = [],
    failedWagers = [],
    failedTickets = []
  } = _ref;
  const customer = isSyndicate ? 'Ert' : 'Ditt';
  let productName = productMapping.getProductName(product) || product;
  if (productMapping.isVikinglotto(product)) {
    const hasJoker = successfulWagers[0].wager.bets.some(b => productMapping.isJoker(b.productId));
    if (hasJoker) productName += ' & Joker';
  }
  if (failedWagers.length || failedTickets.length) {
    return "".concat(customer, " ").concat(productName, " \xE4r betalat, men med en justering.");
  }
  return "".concat(customer, " ").concat(productName, " \xE4r betalat. Lycka till!");
}

function getSuccessMessages(_ref2) {
  let {
    product,
    price,
    isSyndicate,
    syndicate,
    grassrootsReceivers,
    chances,
    successfulTickets = [],
    failedWagers = [],
    failedTickets = []
  } = _ref2;
  const messages = [];
  const mybetsHomeLink = "".concat(svs.core.urlMapping.get('myBetsHome'), "?grassrootstoasterredirect");
  const isAcChances = chances && productMapping.isLotto(product);
  const isCampaignChances = chances && !productMapping.isLotto(product);
  const formatChances = chances => chances > 1 ? "".concat(chances, " nya chanser") : '1 ny chans';
  const customer = isSyndicate ? 'Ni' : 'Du';
  if (failedWagers.length || failedTickets.length) {
    const failedProducts = failedWagers.map(_ref3 => {
      let {
        wager
      } = _ref3;
      return productMapping.getProductDayName(wager.bets[0].productId);
    }).concat(failedTickets.map(ticket => ticket.product));
    messages.push("".concat(formatText.joinWithPunctuation(failedProducts), " gick inte att betala och har d\xE4rf\xF6r exkluderats ur ").concat(isSyndicate ? 'ert' : 'ditt', " k\xF6p. Priset justerades till ").concat(price, " kr."));
  }
  const getMybetsMessage = () => {
    if (isSyndicate) {
      const syndicateLink = "".concat(svs.core.urlMapping.get('playTogetherTeams'), "?syndicateId=").concat(syndicate === null || syndicate === void 0 ? void 0 : syndicate.syndicateId, "&grassrootstoasterredirect");
      return "Du hittar ditt spel under <a href=\"".concat(syndicateLink, "\">Spela tillsammans</a> och <a href=\"").concat(mybetsHomeLink, "\">Mina spel</a>.");
    }
    let message = "Se dina lagda spel i <a href='".concat(mybetsHomeLink, "'>Mina spel</a>.");
    if (chances) message = "Se dina lagda spel och extra chanser i <a href='".concat(mybetsHomeLink, "'>Mina spel</a>.");
    if (successfulTickets.length) message += "<br>D\xE4r v\xE4ntar \xE4ven din Trisslott p\xE5 dig.</br>";
    return message;
  };
  messages.push(getMybetsMessage());
  if (successfulTickets.length) messages.push("<strong>Vill du skrapa din Trisslott direkt?</strong>");
  if (isAcChances) messages.push("".concat(customer, " fick ").concat(formatChances(chances), " att vinna p\xE5 Andra Chansen."));
  if (isCampaignChances) messages.push("".concat(customer, " fick ").concat(formatChances(chances), " i ").concat(productMapping.getProductName(product), " kampanj."));
  if (isSyndicate) messages.push('Spelet skapar Gräsrotenpoäng om lagmedlemmar stöder föreningar');
  if (Array.isArray(grassrootsReceivers) && grassrootsReceivers.length) {
    messages.push("".concat(formatText.joinWithPunctuation(grassrootsReceivers), " tackar f\xF6r ditt st\xF6d genom Gr\xE4sroten."));
  }
  return messages;
}
Object.assign(svs.tur.pay.dialogs, {
  getSuccessTitle,
  getSuccessMessages
});

 })(window);
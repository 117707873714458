(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/player-spelpaus.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/player-spelpaus.js');

'use strict';

const getActiveSuspensionsAsync = async () => {
  try {
    const options = {
      method: 'GET',
      path: '/player/1/suspensions'
    };
    const {
      response,
      status
    } = await svs.core.jupiter.callAsync(options);
    if (status && status === 'success') {
      return response.suspensions.filter(suspension => suspension.product === 'quickGames' || suspension.product === 'all');
    }
    return [];
  } catch (error) {
    return [];
  }
};
async function playerSpelpaus(_ref) {
  let {
    next,
    cancel,
    spelpausFlags = []
  } = _ref;
  const suspendedGames = await getActiveSuspensionsAsync();
  if (suspendedGames.find(_ref2 => {
    let {
      product
    } = _ref2;
    return spelpausFlags.indexOf(product) + 1;
  })) {
    svs.tur.apierrors.showErrorMessage({
      response: 1019,
      onConfirm: cancel
    });
  } else {
    next();
  }
}
svs.tur.pay.account_services.playerSpelpaus = playerSpelpaus;
svs.tur.pay.account_services.playerSpelpaus.CONSTANTS = {
  ALL: 'all',
  QUICK_GAMES: 'quickGames'
};

 })(window);
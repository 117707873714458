(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/utils/format-text.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/utils/format-text.js');
'use strict';

function addOwningGrammarApostrophe(name) {
  return name.slice(-1) === 's' ? name : "".concat(name, "s");
}

function joinWithPunctuation(_ref) {
  let [...arr] = _ref;
  const last = arr.pop();
  return arr.length ? "".concat(arr.join(', '), " och ").concat(last) : last;
}
svs.tur.pay.utils.format_text = {
  addOwningGrammarApostrophe,
  joinWithPunctuation
};

 })(window);